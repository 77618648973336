import axios from "axios";

const API = "https://brasilapi.com.br/api/";

// Api de busca de ceps
export default class ViaCepService {
  public _getCep(cep: string) {
    return axios.get(`${API}cep/v2/${cep}`);
  }
}
