import store from '@/store';
import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

export default class MethodologyModel {
  public getMethodology() {
    return axios.get(`${API_URL}/fuel-factor-lambda-methodology/page`);
  }

  public getMethodologyById(id) {
    return axios.get(`${API_URL}/fuel-factor-lambda-methodology/${id}`);
  }

  public getMethodologyActual() {
    return axios.get(
      `${API_URL}/fuel-factor-lambda-methodology/buscar-fator-atual`
    );
  }

  public editMethodology(id) {
    return axios.put(`${API_URL}/fuel-factor-lambda-methodology/${id}`);
  }

  public setMethodology(data) {
    return axios.post(`${API_URL}/fuel-factor-lambda-methodology/`, data);
  }

  public deleteMethodology(id) {
    axios.delete(`${API_URL}/fuel-factor-lambda-methodology/${id}`);
  }
}
