import axios from "axios";

const API = "https://servicodados.ibge.gov.br/api/v1/localidades";

// APi para obter Estados e cidades
export default class IBGEService {
  public _getStates() {
    return axios.get(`${API}/estados`);
  }

  public _getCities(state: string) {
    return axios.get(`${API}/estados/${state}/municipios`);
  }
}
