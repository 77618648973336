
import { Vue, Options } from 'vue-class-component';
import UserController from '@/AppClubCarby/Controller/UserController';
import GasStationController from '@/AppClubCarby/Controller/GasStationController';
import ViaCepService from '@/services/ViaCepService';
import {
  GasStationInterface,
  SetGasStationModel,
} from '@/models/GasStationModel';
import { CepIterface } from '@/models/viaCepModel';
import { GetUserModel } from '@/models/UserModel';

import useVuelidate from '@vuelidate/core';
import { required, email, minLength } from '@vuelidate/validators';

import { cnpjWithoutMask, formatReal, removeCurrency } from '@/mixins';
import Swal from 'sweetalert2';

@Options({
  beforeRouteEnter(to, from, next) {
    let role = localStorage.getItem('ROLE');
    if (role === 'ROLE_ADMIN') {
      next();
    } else {
      next('/not-found');
    }
  },
  created() {
    this.getNetworks();
  },
  data() {
    return {
      loading: false,
      v$: useVuelidate(),
      gasStation: new SetGasStationModel(),
      userController: new UserController(),
      gasStationController: new GasStationController(),
      viaCepService: new ViaCepService(),
      cep: '',
      cnpj: '',
      users: [],
      register: true,
      isLoading: false,
      networks: [],
      searchingCep: false,
      feeUnformatted: 0,
    };
  },
  validations() {
    return {
      gasStation: {
        socialReason: { required },
        fantasyName: { required },
        email: { required, email },
        cnpj: { required, minLength: minLength(18) },
        address: {
          cep: { required, minLength: minLength(10) },
          uf: { required },
          city: { required },
          number: { required },
          street: { required },
        },
      },
    };
  },
  methods: {
    formatFee(feeValue) {
      const str = feeValue.toString();
      const nmbr = parseFloat(str.replace(',', '.'));
      return nmbr;
    },
    searchCep() {
      this.v$.gasStation.address.cep.$touch();
      if (this.v$.gasStation.address.cep.$error) {
        return;
      } else {
        this.searchingCep = true;
        const cepElement = document.getElementById('cep');
        let receivedCep = cepElement?.dataset.maskRawValue;

        this.viaCepService
          ._getCep(receivedCep)
          .then((item: CepIterface) => {
            this.gasStation.address.street = item.data.street;
            this.gasStation.address.city = item.data.city;
            this.gasStation.address.uf = item.data.state;
            this.gasStation.address.latitude =
              item.data.location.coordinates.latitude;
            this.gasStation.address.longitude =
              item.data.location.coordinates.longitude;

            setTimeout(() => {
              this.searchingCep = false;
            }, 500);
          })
          .catch((error) => {
            console.log(error);
            this.searchingCep = false;

            Swal.fire({
              title: 'Oops!',
              text: 'CEP não encontrado. Verifique a digitação e tente novamente.',
              icon: 'error',
              confirmButtonText: 'Ok',
            });
          });

        this.v$.gasStation.address.number.$touch();
      }
    },
    getNetworks() {
      this.gasStationController.getGasStationNetworks(0).then((data) => {
        this.networks = data.data.content;
      });
    },
    registerGasStation() {
      this.isLoading = true;

      if (this.feeUnformatted !== 0) {
        this.gasStation.fee = this.formatFee(this.feeUnformatted);
      }

      console.log(this.gasStation);

      if (this.gasStation.gasoline) {
        this.gasStation.gasoline = this.formatNumber(this.gasStation.gasoline);
      }

      if (this.gasStation.gasolineAdditive) {
        this.gasStation.gasolineAdditive = this.formatNumber(
          this.gasStation.gasolineAdditive
        );
      }

      if (this.gasStation.ethanol) {
        this.gasStation.ethanol = this.formatNumber(this.gasStation.ethanol);
      }

      if (this.gasStation.diesel != null) {
        this.gasStation.diesel = this.formatNumber(this.gasStation.diesel);
      }

      this.gasStation.carbyCashbackMargin = this.formatNumber(
        this.gasStation.carbyCashbackMargin
      );
      this.gasStation.priceUsePlatform = this.formatNumber(
        this.gasStation.priceUsePlatform
      );

      this.gasStation.address.surname = `Endereço ${this.gasStation.socialReason}`;

      console.log(this.gasStation);
      this.v$.$touch();
      if (this.v$.$error) {
        this.isLoading = false;
        Swal.fire({
          title: 'Oops!',
          text: 'Algum erro inesperado aconteceu! Verifique todos os campos e tente novamente.',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        return;
      } else {
        this.gasStation.cnpj = cnpjWithoutMask(this.gasStation.cnpj);
        this.gasStationController
          ._setGasStation(this.gasStation)
          .then(() => {
            Swal.fire({
              title: 'Yay!',
              text: 'Posto cadastrado com sucesso!',
              icon: 'success',
              confirmButtonText: 'Ok',
            });
            this.gasStation = new SetGasStationModel();
            this.v$.$reset();
          })
          .catch((error) => {
            this.isLoading = false;
            const errosObtidos = error.response.data.erros
              ? Object.values(error.response.data.erros)
              : error.response.data.mensagem;
            const mensagemDeErro = error.response.data.erros
              ? errosObtidos.join('. ')
              : errosObtidos;
            Swal.fire({
              title: 'Oops!',
              text: mensagemDeErro,
              icon: 'error',
              confirmButtonText: 'Ok',
            });
            this.gasStation = new SetGasStationModel();
            this.v$.$reset();
          });
        this.isLoading = false;
      }
    },

    async edit() {
      this.isLoading = true;
      await this.gasStationController
        ._updateGasStation(this.gasStation)
        .then(() => {
          this.isLoading = false;
          Swal.fire({
            title: 'Yay!',
            text: 'Posto alterado com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
        })
        .catch((error: any) => {
          this.isLoading = false;
          Swal.fire({
            title: 'Oops!',
            text: 'Algum erro inesperado aconteceu!',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        });
    },
    formatNumber(number) {
      return parseFloat(removeCurrency(number));
    },
  },
})
export default class RegisterGasStation extends Vue {}
