
import { Vue, Options } from "vue-class-component";
import HomeController from "@/AppClubCarby/Controller/HomeController";

@Options({
  created() {
    this.homeController._homeCreated(this.role);
  },
  computed: {
    role: function () {
      return localStorage.getItem("ROLE");
    },
  },
  data() {
    return {
      homeController: new HomeController(),
    };
  },
})
export default class AppHome extends Vue {}
