import { SetAddressModel } from "./AddressModel";

export class SetCatalogModel {
  address = new SetAddressModel();
  cnpj = null;
  id = null;
  networkId = null;
  fantasyName = null;
  partner = {
    id: null,
  };
  socialReason = null;
  logo = null;
}
