import { GetAddressModel, SetAddressModel } from './AddressModel';

export interface NetworkInterface {
  id: number;
  name: string;
  quantity: number;
  gasStations: null;
  cashback: number;
}

export interface GasStationInterface {
  data: {
    content: [
      {
        address: GetAddressModel;
        cnpj: string;
        diesel: string;
        email: string;
        ethanol: number;
        ethanolAdditive: number;
        fantasyName: string;
        gasoline: number;
        gasolineAdditive: number;
        gasStationId: number;
        phone: string;
        socialReason: string;
        network: NetworkInterface;
        id?: number;
      }
    ];
  };
}

export class GetGasStationModel {
  address = new GetAddressModel();
  cnpj = null;
  diesel = null;
  email = null;
  ethanol = null;
  ethanolAdditive = null;
  fantasyName = null;
  gasoline = null;
  gasolineAdditive = null;
  id = null;
  phone = null;
  socialReason = null;
  network: NetworkInterface;
  fee = null;
}

export class SetGasStationModel {
  address = new SetAddressModel();
  cnpj = null;
  diesel = null;
  email = null;
  ethanol = null;
  ethanolAdditive = null;
  fantasyName = null;
  gasoline = null;
  gasolineAdditive = null;
  phone = null;
  socialReason = null;
  network: NetworkInterface;
  fee = null;
}
