import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

//Essentials
import UserController from '@/AppClubCarby/Controller/UserController';
import ClubCarbyRouter from '@/AppClubCarby/Router/ClubCarbyRouter';
import ForgotPassword from '@/LandingPage/View/ForgotPassword/ForgotPassword.vue';
import Login from '@/LandingPage/View/Login/Login.vue';
import LandingPage from '@/LandingPage/View/Main/LandingPage.vue';
import PreSignUp from '@/LandingPage/View/SignUp/PreSignUp.vue';
import SignUp from '@/LandingPage/View/SignUp/SignUp.vue';
import store from '@/store';

import { getCookie, removeCookie } from '@/mixins';

import RouterGuards from './routesGuards';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: LandingPage,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/pre-signup',
    name: 'Pre-signup',
    component: PreSignUp,
  },
  {
    path: '/signup',
    name: 'Sign Up ',
    component: SignUp,
    children: [
      {
        path: ':id',
        name: 'Invite Friends',
        component: SignUp,
      },
    ],
  },

  {
    path: '/forgot-password',
    name: 'Esqueci minha senha',
    component: ForgotPassword,
  },
  ...ClubCarbyRouter,
];

const routerGuard = new RouterGuards();
const userController = new UserController();

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
});

router.beforeResolve((to, from) => {
  const loggedIn = !!getCookie('AUTHORIZATION');
  const keepLoggedIn = !!getCookie('keepLoggedIn');

  if (loggedIn) {
    store.dispatch('decodeToken');
    // console.log(Date.now(), store.state.decodedToken.exp);
    if (keepLoggedIn) {
      if (Date.now() >= store.state.decodedToken.exp) {
        userController.refreshToken();
      }
    }
  }
});

router.beforeEach(async (to, from, next) => {
  const publicPages = [
    '/forgot-password',
    '/login',
    '/signup',
    '/pre-signup',
    'not-found',
    '/',
  ];
  const authRequired = !publicPages.includes(to.matched[0].path);
  const loggedIn = !!getCookie('AUTHORIZATION');
  const keepLoggedIn = !!getCookie('keepLoggedIn');

  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    if (to.fullPath === '/app' || to.fullPath === '/app/') {
      next('/app/home');
    } else {
      next();
    }
  }
});

export default router;
