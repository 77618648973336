
// Model interface
export interface AddressInterface {
  id?: number;
  cep?: string;
  city?: string;
  country?: string;
  complement?: string;
  district?: string;
  latitude?: string;
  longitude?: string;
  number?: string;
  street?: string;
  surname: string;
  uf?: string;
}

// Model Endereço colocar
export class SetAddressModel {
  cep = null;
  city = null;
  country = null;
  complement = null;
  district = null;
  latitude = null;
  longitude = null;
  number = null;
  street = null;
  surname = null;
  uf = null;
}

// Model Endereço Obter
export class GetAddressModel {
  id = null;
  cep = null;
  city = null;
  country = null;
  complement = null;
  district = null;
  latitude = null;
  longitude = null;
  number = null;
  street = null;
  surname = null;
  uf = null;
}
