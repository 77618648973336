
import { Vue, Options } from 'vue-class-component';
import ModalComponent from '@/LandingPage/Components/ModalComponent/ModalComponent.vue';

import loading from 'vue3-loading-overlay';
import CardComponent from '@/AppClubCarby/Components/CardComponent/CardComponent.vue';
import GasStationController from '@/AppClubCarby/Controller/GasStationController';
import { useVuelidate } from '@vuelidate/core';

import { minLength, required } from '@vuelidate/validators';

@Options({
  data() {
    return {
      isLoading: false,
      v$: useVuelidate(),
      registerNetworkModal: false,
      confirmationNetworkRegisterModal: false,
      editModal: false,
      searchName: null,
      withoutNetworks: false,
      gasStationNetworks: [],
      searchingNetwork: false,
      networksFounded: [],
      newGasStationNetwork: { name: null },
      gasStationController: new GasStationController(),
      lastTablePage: false,
      editableNetwork: {
        /*         id: null,
      gasStations: [], */
        name: null,
      },
      editableId: null,
    };
  },
  mounted() {
    const listElm = document.querySelector('#extractInfiniteScroll');

    let page = 0;

    listElm.addEventListener('scroll', (e) => {
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        page++;

        this.updateTable(page);
      }
    });
  },
  created() {
    this.isLoading = true;
    this.getNetworkList();
  },
  validations() {
    return {
      newGasStationNetwork: {
        name: {
          required,
          minLength: minLength(3),
        },
      },
    };
  },
  methods: {
    confirmRegistration() {
      this.v$.newGasStationNetwork.name.$touch();
      if (this.v$.newGasStationNetwork.name.$error) {
        return;
      } else {
        let network = this.newGasStationNetwork;
        this.isLoading = true;
        this.gasStationController
          .registerGasStationNetwork(network)
          .then(() => {
            this.isLoading = false;
            console.log(network);
            this.registerNetworkModal = false;
            this.confirmationNetworkRegisterModal = true;
            this.newGasStationNetwork.name = null;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      }
    },
    updateTable(page) {
      if (!this.lastTablePage) {
        this.gasStationController.getGasStationNetworks(page).then((data) => {
          console.log(data);
          this.lastTablePage = data.last;
        });
      }
    },
    getNetworkList() {
      this.gasStationController.getGasStationNetworks(0).then((data) => {
        console.log(data.data);
        let gasStationList = data.data.content;
        if (gasStationList.length == 0) {
          this.withoutNetworks = true;
        } else {
          this.withoutNetworks = false;
        }
        this.gasStationNetworks = gasStationList;
        this.isLoading = false;
      });
    },
    toggleModals(modal) {
      if (modal == 'registration') {
        this.registerNetworkModal = !this.registerNetworkModal;
      }

      if (modal == 'confirmation') {
        this.confirmationNetworkRegisterModal = false;
        this.getNetworkList();
      }

      if (modal == 'edit') {
        this.editModal = false;
        this.getNetworkList();
      }

      if (modal == 'edit-clear') {
        this.editModal = false;
        this.editableId = null;
        this.editNetwork.name = null;
        this.editNetwork.gasStations = null;
      }
    },
    editNetwork(value) {
      this.isLoading = true;
      this.editModal = true;
      this.gasStationController
        .getNetworkById(value)
        .then((data) => {
          console.log(data.data);
          this.editableNetwork.name = data.data.name;
          this.editableId = data.data.id;

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
      console.log(value);
    },
    updateNetwork(id, data) {
      console.log(data);
      this.gasStationController.editGasStationNetwork(id, data).then(() => {
        this.editableNetwork.name = null;
        this.editableNetwork.id = null;
        this.editableNetwork.gasStations = [];
      });
    },

    searchNetwork() {
      if (this.searchName.length < 1) {
        this.searchingNetwork = false;
      } else {
        this.searchingNetwork = true;
        this.gasStationController
          .searchNetworkByName(this.searchName)
          .then((data) => {
            this.searchingNetwork = true;
            this.networksFounded = data.data.content;
          });
      }
    },
  },
  components: {
    loading,
    ModalComponent,
    CardComponent,
  },
})
export default class AppGasStationNetwork extends Vue {}
