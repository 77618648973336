
import { Options, Vue } from "vue-class-component";
import MenuApp from "@/AppClubCarby/Components/MenuApp/MenuApp.vue";
import ProfileComponent from "@/AppClubCarby/Components/ProfileComponent/ProfileComponent.vue";

@Options({
  components: {
    MenuApp,
    ProfileComponent,
  },
  data() {
    return {
      modalActive: false,
    };
  },
  computed: {
    role: function () {
      return localStorage.getItem("ROLE");
    },
  },
  methods: {
    openModal() {
      this.modalActive = !this.modalActive;
    },
    getCookie(cname: any) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
})
export default class extends Vue {}
