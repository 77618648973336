import "@/assets/css/datepicker.scss";
import Notifications from "@kyvg/vue3-notification";
import { maska } from "maska";
import { createApp } from "vue";
import { VueCookieNext } from "vue-cookie-next";
import VueDatepickerUi from "vue-datepicker-ui";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueApexCharts from "vue3-apexcharts";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import Axios from "axios";

const app = createApp(App);

const token = localStorage.getItem("token");
app.config.globalProperties.$http = Axios;

if (token) {
  app.config.globalProperties.$http.defaults.headers.common["Authorization"] =
    token;
}

app
  .directive("maska", maska)
  .use(VueApexCharts, null)
  .use(Notifications)
  .use(store)
  .use(router)
  .use(VueCookieNext)
  .mount("#app");
app.component("Datepicker", VueDatepickerUi);
