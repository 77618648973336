// import UserModel from "../Model/UserModel";
import UserController from './UserController';
import router from "@/router";

import store from "@/store";

const userService = new UserController();

export default class HomeController {
  public _homeCreated(role: string) {
    if (role === "ROLE_MANAGER") {
      // userService.getOneUserByEmail().then((item: any) => {
      //   store.dispatch("setManagerGasStation", item.gasStations[0].id);
      // })
      router.push("/app/dashboard-posto");
    }

    if (role === "ROLE_ATTENDANT") {
      router.push("/app/compra-cliente");
    }

    if (role === "ROLE_CLIENT") {
      router.push("/app/minha-pegada-de-carbono");
    }

    if (role === "ROLE_PARTNER") {
      router.push("/app/transacoes");
    }

    if (role === "ROLE_ADMIN") {
      router.push("/app/admin/users");
    }
  }
}
