
import { onMounted, onUpdated } from "vue-demi";
import axios from "axios";
import store from "./store";
import RouterGuards from "@/router/routesGuards";

const routerGuard = new RouterGuards();
export default {
  setup(): void {
    onMounted(() => {
      axios.interceptors.response.use(undefined, function (err) {
        return new Promise(function () {
          if (
            err.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            store.dispatch("logout");
          }
          throw err;
        });
      });
    });
  },
};
