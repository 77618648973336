import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

export default class ManagerExtractModel {
  async _getManagerExtract(
    gasStationId: number,
    page: number,
    linesPerPage: number,
    direction: string,
    orderBy: string,
    cpf?: string
  ) {
    if (cpf) {
      return axios.get(
        `${API_URL}/fuelTransaction/gas-station-extract/?page=${page}&linesPerPage=${linesPerPage}&direction=${direction}&orderBy=${orderBy}&gasStationId=${gasStationId}&clientCpf=${cpf}`
      );
    } else {
      return axios.get(
        `${API_URL}/fuelTransaction/gas-station-extract/?page=${page}&linesPerPage=${linesPerPage}&direction=${direction}&orderBy=${orderBy}&gasStationId=${gasStationId}`
      );
    }
  }

  async getFuelTransactionsByGasStationAndCashbackUse(
    page,
    linesPerPage,
    direction,
    orderBy,
    gasStationId,
    isUsedCashback?
  ) {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day > 9 ? day : `0${day}`;
    const formattedMonth = month > 9 ? month : `0${month}`;

    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
    if (isUsedCashback == "allTransactions") {
      return axios.get(
        `${API_URL}/fuel-transaction/history?page=${page}&linesPerPage=${linesPerPage}&direction=${direction}&orderBy=${orderBy}&gasStationId=${gasStationId}&endDate=${formattedDate}`
      );
    } else {
      console.log("isUsedCashback");
      return axios.get(
        `${API_URL}/fuel-transaction/history?page=${page}&linesPerPage=${linesPerPage}&direction=${direction}&orderBy=${orderBy}&gasStationId=${gasStationId}&isCashback=${isUsedCashback}&endDate=${formattedDate}`
      );
    }
  }

  async _getTotalValues(gasStationId: number, cpf?) {
    if (cpf) {
      return axios.get(
        `${API_URL}/fuelTransaction/gas-station-extract-total-amounts/?gasStationId=${gasStationId}&clientCpf=${cpf}`
      );
    } else {
      return axios.get(
        `${API_URL}/fuelTransaction/gas-station-extract-total-amounts/?gasStationId=${gasStationId}`
      );
    }
  }

  async getFuelTransactionHistory(
    gasStationId,
    searchTerm,
    startDate,
    endDate,
    page,
    linesPerPage,
    direction,
    orderBy
  ) {
    let url = `${API_URL}/fuel-transaction/history?gasStationId=${gasStationId}&page=${page}&linesPerPage=${linesPerPage}&direction=${direction}&orderBy=${orderBy}`;

    if (searchTerm) {
      url += `&searchTerm=${searchTerm}`;
    }

    if (startDate) {
      url += `&startDate=${startDate}`;
    }

    if (endDate) {
      url += `&endDate=${endDate}`;
    }

    return axios.get(url);
  }

  async getTotalValuesHistory(
    gasStationId,
    searchTerm,
    startDate,
    endDate,
    isUsedCashback
  ) {
    let url = `${API_URL}/fuel-transaction/history/total-amounts?gasStationId=${gasStationId}`;

    if (searchTerm) {
      url += `&searchTerm=${searchTerm}`;
    }

    if (startDate) {
      url += `&startDate=${startDate}`;
    }

    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    if (isUsedCashback != "allTransactions" && isUsedCashback != null) {
      url += `&isCashback=${isUsedCashback}`;
    }

    return axios.get(url);
  }

  async downloadExtract(gasStationId, startDate, endDate) {
    const url = `${API_URL}/fuel-transaction/history/download-csv?gasStationId=${gasStationId}&startDate=${startDate}&endDate=${endDate}`;

    return axios.get(url);
  }
}
