import RankingModel from '../Model/RankingModel';
const rankingModel = new RankingModel();

export default class RankingController {
  async getClients(id: number, startDate, endDate) {
    const data = {
      topAmount: [],
      topCashback: [],
      topFuelTransactions: [],
      topEmission: [],
      topGalonage: [],
      topCarbonOffset: [],
    };

    await rankingModel
      .getClientTopAmount(id, startDate, endDate)
      .then((item: any) => {
        data.topAmount = item.data.map((data) => {
          return {
            ...data,
            type: 'R$',
          };
        });
      });

    await rankingModel
      .getClientTopCashback(id, startDate, endDate)
      .then((item: any) => {
        data.topCashback = item.data.map((data) => {
          return {
            ...data,
            type: 'R$',
          };
        });
      });

    await rankingModel
      .getClientTopEmission(id, startDate, endDate)
      .then((item: any) => {
        data.topEmission = item.data.map((data) => {
          return {
            ...data,
            type: 'Kg CO2e',
          };
        });
      });

    await rankingModel
      .getClientTopFuelTransactions(id, startDate, endDate)
      .then((item: any) => {
        data.topFuelTransactions = item.data.map((data) => {
          return {
            ...data,
            type: '',
          };
        });
      });

    await rankingModel
      .getClientTopGalonage(id, startDate, endDate)
      .then((item: any) => {
        data.topGalonage = item.data.map((data) => {
          return {
            ...data,
            type: 'L',
          };
        });
      });

    await rankingModel
      .getClientTopCarbonOffset(id, startDate, endDate)
      .then((item: any) => {
        data.topCarbonOffset = item.data.map((data) => {
          return {
            ...data,
            type: 'Kg CO2e',
          };
        });
      });

    return data;
  }

  async getAttendants(id: number, startDate, endDate) {
    const data = {
      topAmount: [],
      topFuelTransactions: [],
      topGalonage: [],
      topScore: [],
    };
    await rankingModel
      .getAttendantTopAmount(id, startDate, endDate)
      .then((item: any) => {
        data.topAmount = item.data.map((data) => {
          return {
            ...data,
            type: 'R$',
          };
        });
      });

    await rankingModel
      .getAttendandTopFuelTransactions(id, startDate, endDate)
      .then((item: any) => {
        data.topFuelTransactions = item.data.map((data) => {
          return {
            ...data,
            type: '',
          };
        });
      });

    await rankingModel
      .getAttendantTopGalonage(id, startDate, endDate)
      .then((item: any) => {
        data.topGalonage = item.data.map((data) => {
          return {
            ...data,
            type: 'L',
          };
        });
      });

    await rankingModel
      .getAttendantScore(id, startDate, endDate)
      .then((item) => {
        data.topScore = item.data.map((data) => {
          return {
            ...data,
            type: '',
          };
        });
      });

    return data;
  }
}
