import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;
const attendantURL = `${API_URL}/fuelTransaction/attendant`;
const clientURL = `${API_URL}/fuelTransaction/client`;

export default class RankingModel {
  // Attendants

  getAttendantTopAmount(id: number, startDate, endDate) {
    return axios.get(
      `${attendantURL}/top-amount?gas_station_id=${id}&limit=100&start_date=${startDate}&end_date=${endDate}`
    );
  }

  getAttendandTopFuelTransactions(id: number, startDate, endDate) {
    return axios.get(
      `${attendantURL}/top-fuel-transactions?gas_station_id=${id}&limit=100&start_date=${startDate}&end_date=${endDate}`
    );
  }

  getAttendantTopGalonage(id: number, startDate, endDate) {
    return axios.get(
      `${attendantURL}/top-galonage?gas_station_id=${id}&limit=100&start_date=${startDate}&end_date=${endDate}`
    );
  }

  getAttendantScore(id, startDate, endDate) {
    return axios.get(
      `${API_URL}/fuelTransactionAttendantScore/top-attendant-score?gasStationId=${id}&startDate=${startDate}&endDate=${endDate}`
    );
  }

  //Clients

  getClientTopAmount(id: number, startDate, endDate) {
    return axios.get(
      `${clientURL}/top-amount?gas_station_id=${id}&limit=100&start_date=${startDate}&end_date=${endDate}`
    );
  }

  getClientTopCashback(id: number, startDate, endDate) {
    return axios.get(
      `${clientURL}/top-cashback?gas_station_id=${id}&limit=100&start_date=${startDate}&end_date=${endDate}`
    );
  }

  getClientTopFuelTransactions(id: number, startDate, endDate) {
    return axios.get(
      `${clientURL}/top-fuel-transactions?gas_station_id=${id}&limit=100&start_date=${startDate}&end_date=${endDate}`
    );
  }

  getClientTopEmission(id: number, startDate, endDate) {
    return axios.get(
      `${clientURL}/top-emission?gas_station_id=${id}&limit=100&start_date=${startDate}&end_date=${endDate}`
    );
  }

  getClientTopGalonage(id: number, startDate, endDate) {
    return axios.get(
      `${clientURL}/top-galonage?gas_station_id=${id}&limit=100&start_date=${startDate}&end_date=${endDate}`
    );
  }

  getClientTopCarbonOffset(id: number, startDate, endDate) {
    return axios.get(
      `${clientURL}/top-carbon-offset?gas_station_id=${id}&limit=100&start_date=${startDate}&end_date=${endDate}`
    );
  }
}
