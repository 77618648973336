
import { Options, Vue } from "vue-class-component";

@Options({
  created() {
    if (localStorage.getItem("token")) {
      this.link = "/app/home";
    } else {
      this.link = "/";
    }
  },
  data() {
    return {
      link: "",
    };
  },
})
export default class NotFound extends Vue {}
