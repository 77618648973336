<template>
    <div class="carousel-indicators">
        <button class="carousel-indicator-item" 
        :class="{active:currentIndex === index}"
         v-for="(item, index) in total" 
         :key="index"
         @click="$emit('switch',index)"
         ></button>

    </div>
</template>

<script>
export default{
    emits:['switch'],
    props:["total", "currentIndex"]
}
</script>

<style scoped>
.carousel-indicator-item{
    width:60px;
    height: 2px;
    border: none;
    background-color: #3B3C3D;
    border-radius: 10px;
    opacity: .5;
    margin: 0.2rem;
    cursor: pointer;
}
.active{
    opacity: 1;
}

</style>