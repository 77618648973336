<template>
  <a class="contact-us" href="https://api.whatsapp.com/send?phone=5527997516811&text=Ol%C3%A1%20ClubCarby,%20preciso%20de%20informa%C3%A7%C3%B5es,%20poderia%20me%20auxiliar?" target="_blank">
    <div class="chat-bubble"><img src="../../../assets/landing-page/wpp-logo.svg" alt="whatsapp"></div>
  </a>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
@import "./NeedHelpComponent.scss";
</style>
