import ContactModel from '../Model/ContactModel';

export default class ContactController {
  contactModel = new ContactModel();

  async sendContact(contactInfo) {
    return await this.contactModel.sendContact(contactInfo);
  }

  async sendBudgetInfo(budgetInfo) {
    return await this.contactModel.sendBudgetInfo(budgetInfo);
  }
}
