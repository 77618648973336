
import { Vue, Options } from "vue-class-component";
import CardComponent from "@/AppClubCarby/Components/CardComponent/CardComponent.vue";
import MonthlyPaymentController from "@/AppClubCarby/Controller/MonthlyPaymentController";
import { formatCurrency } from "@/mixins";
import GasStationController from "@/AppClubCarby/Controller/GasStationController";
import UserController from "@/AppClubCarby/Controller/UserController";

@Options({
  data() {
    return {
      monthlyPaymentController: new MonthlyPaymentController(),
      gasStationController: new GasStationController(),
      userController: new UserController(),
      historic: [],
      loading: true,
    };
  },
  components: {
    CardComponent,
  },
  mounted() {
    this.getHistoric();
  },
  inject: {
    id: {
      from: "monthlyGasStationId",
    },
  },
  watch: {
    "id.value": function () {
      this.getHistoric();
    },
  },
  methods: {
    async getHistoric() {
      this.loading = true;
      await this.monthlyPaymentController
        .getMonthlyParametersByGasStationId(this.id.value)
        .then((data) => {
          console.log(data);
          this.historic = data.data;
        });

      this.loading = false;
    },
    returnFormatNumber(number) {
      return formatCurrency(number);
    },
    formatDate(date) {
      let month = date.slice(4);

      let year = date.slice(0, 4);

      let actualDate = `${month}/${year}`;
      return actualDate;
    },
  },
})
export default class CashbackUse extends Vue {}
