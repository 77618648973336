import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL;

export default class ClientPurchaseModel {
  async _setFuelTransaction(data: any) {
    return axios.post(`${API_URL}/fuelTransaction`, data);
  }

  private _putFuelTransaction() {
    return axios.put(`${API_URL}/fuelTransaction`);
  }

  public getFuelTransactionById(id: number) {
    return axios.get(`${API_URL}/fuelTransaction/${id}`);
  }

  public _editFuelTransactionById(id: number, data) {
    return axios.put(`${API_URL}/fuelTransaction/${id}`, data);
  }

  private _getFuelTransaction() {
    return axios.get(`${API_URL}/fuelTransaction/page`);
  }

  private _deleteFuelTransaction() {
    return axios.delete(`${API_URL}/fuelTransaction`);
  }

  async _findClientByCpf(cpf: string) {
    return axios.get(`${API_URL}/user/client/findByCpfContains?cpf=${cpf}`);
  }

  async _getCashbackAmount(clientId: number) {
    return axios.get(`${API_URL}/exchangeEcopoint/cashback/${clientId}`);
  }

  async getClientCashbackByGasStationId(
    clientId: number,
    gasStationId: number
  ) {
    return axios.get(
      `${API_URL}/user/client/cashback-by-gas-station?client_id=${clientId}&gas_station_id=${gasStationId}`
    );
  }
}
