import MonthlyPaymentModel from '../Model/MonthlyPaymentModel';

const monthPaymentModel = new MonthlyPaymentModel();

export default class MonthlyPaymentController {
  public async getMonthlyPaymentById(id) {
    return await monthPaymentModel
      .getMonthlyPaymentById(id)
      .then((data) => {
        console.log(data);
        return data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }

  public async getMonthlyParametersByGasStationId(gasStationId) {
    return await monthPaymentModel
      .getMonthlyParametersByGasStationId(gasStationId)
      .then((data) => {
        return data;
      });
  }

  public async getParametersByGasStationId(gasStationId: number) {
    return await monthPaymentModel
      .getParametersByGasStationId(gasStationId)
      .then((data) => {
        return data;
      });
  }

  public async getHistoric() {
    return await monthPaymentModel
      .getHistoric()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
}
