
import { Vue, Options } from "vue-class-component";

@Options({
  data() {
    return {
      user: [],
    };
  },
})
export default class CarbonFootprintPartner extends Vue {}
