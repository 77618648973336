import AppContainer from "@/AppClubCarby/View/App/AppContainer/AppContainer.vue";

import NotFound from "@/AppClubCarby/View/404/NotFound.vue";
import AppAttendant from "@/AppClubCarby/View/App/AppAttendant/AppAttendant.vue";
import Cashback from "@/AppClubCarby/View/App/AppCashback/AppCashback.vue";
import PointsExchangePartber from "@/AppClubCarby/View/App/AppCashback/AppExchangePartner.vue";
import ClientPurchase from "@/AppClubCarby/View/App/AppClientPurchase/AppClientPurchase.vue";
import ClientRanking from "@/AppClubCarby/View/App/AppClientRanking/AppClientRanking.vue";
import AppExtract from "@/AppClubCarby/View/App/AppExtract/AppExtract.vue";
import CarbonFootprint from "@/AppClubCarby/View/App/AppFootprint/AppFootprint.vue";
import CarbonFootprintPartner from "@/AppClubCarby/View/App/AppFootprint/AppFootprintParner.vue";
import AppGasStationsList from "@/AppClubCarby/View/App/AppGasStationsList/AppGasStationsList.vue";
import AppHome from "@/AppClubCarby/View/App/AppHome/AppHome.vue";
import MyConsumption from "@/AppClubCarby/View/App/AppMyConsumption/AppMyConsumption.vue";
import AppPartnerList from "@/AppClubCarby/View/App/AppPartnerList/AppPartnerList.vue";
import PriceUpdate from "@/AppClubCarby/View/App/AppPriceUpdate/AppPriceUpdate.vue";
import AppProfile from "@/AppClubCarby/View/App/AppProfile/AppProfile.vue";
import AppTransactionsRecord from "@/AppClubCarby/View/App/AppTransactionsRecord/AppTransactionsRecord.vue";
import ManagerDashboard from "@/AppClubCarby/View/App/ManagerDashboard/ManagerDashboard.vue";
import ManagerTargets from "@/AppClubCarby/View/App/ManagerTargets/ManagerTargets.vue";

//ADMIN Views
import AppCarbon from "@/AppClubCarby/View/App/Admin/AppCarbon/AppCarbon.vue";
import AppEditNetwork from "@/AppClubCarby/View/App/Admin/AppGasStationNetwork/AppEditNetwork.vue";
import AppGasStationNetwork from "@/AppClubCarby/View/App/Admin/AppGasStationNetwork/AppGasStationNetwork.vue";
import AppGasStation from "@/AppClubCarby/View/App/Admin/AppGasStations/AppGasStation.vue";
import RegisterGasStation from "@/AppClubCarby/View/App/Admin/AppGasStations/RegisterGasStation.vue";
import AppMethodology from "@/AppClubCarby/View/App/Admin/AppMethodology/AppMethodology.vue";
import AppMonthlyPayment from "@/AppClubCarby/View/App/Admin/AppMonthlyPayment/AppMonthlyPayment.vue";
import CatalogRegister from "@/AppClubCarby/View/App/Admin/AppStores/CatalogRegister.vue";
import Users from "@/AppClubCarby/View/App/Admin/RegisterUsers/Users.vue";
import UsersAdmin from "@/AppClubCarby/View/App/Admin/RegisterUsers/Admin/UsersAdmin.vue";
import AppUsersAttendant from "@/AppClubCarby/View/App/Admin/RegisterUsers/Attendant/AppUsersAttendant.vue";
import AppUsersManager from "@/AppClubCarby/View/App/Admin/RegisterUsers/Manager/AppUsersManager.vue";
import AppUsersPartner from "@/AppClubCarby/View/App/Admin/RegisterUsers/Partner/AppUsersPartner.vue";
import EditCashbackUse from "@/AppClubCarby/View/App/Admin/RegisterUsers/EditCashbackUse.vue";
import EditFuelTransactions from "@/AppClubCarby/View/App/Admin/RegisterUsers/EditFuelTransaction.vue";
import EditRegisterData from "@/AppClubCarby/View/App/Admin/RegisterUsers/EditRegisterData.vue";
import AppUsersManagerRegister from "../View/App/Admin/RegisterUsers/Manager/AppUsersManagerRegister.vue";

import AppTraining from "@/AppClubCarby/View/App/AppTraining/AppTraining.vue";

import { getRouteRole } from "@/mixins";
import { RouteRecordRaw } from "vue-router";

const getRole = getRouteRole();

const ClubCarbyRouter: Array<RouteRecordRaw> = [
  {
    path: "/app",
    name: "Home App Carby",
    component: AppContainer,
    children: [
      {
        path: "home",
        name: "Home App Carby",
        component: AppHome,
      },
      {
        path: "account",
        name: "Perfil",
        component: AppProfile,
      },
      {
        path: "complete-profile/:id",
        name: "Completar Cadastro",
        component: AppProfile,
      },
      {
        path: "compra-cliente",
        name: "Compra Cliente App Carby ",
        component: ClientPurchase,
      },
      // {
      //   path: "atualizacao-precos",
      //   name: "Atualização de Preços",
      //   component: PriceUpdate,
      // },
      {
        path: "cashback",
        name: "Troque seus Ecopontos",
        component: Cashback,
      },
      {
        path: "minha-pegada-de-carbono",
        name: "Minha Pegada de Carbono",
        component: CarbonFootprint,
      },
      {
        path: "meu-consumo",
        name: "Meu Consumo",
        component: MyConsumption,
      },
      {
        path: "transacoes",
        name: "Transações",
        component: PointsExchangePartber,
      },
      {
        path: "pegada-de-carbono",
        name: "Pegada de Carbono",
        component: CarbonFootprintPartner,
      },
      {
        path: "dashboard-posto",
        name: "Dashboard Posto",
        component: ManagerDashboard,
      },
      {
        path: "metas",
        name: "Metas",
        component: ManagerTargets,
      },
      // {
      //   path: "lojas-parceiras",
      //   name: "Lojas Parceiras",
      //   component: AppPartnerList,
      // },
      {
        path: "registro-transacoes",
        name: "Registro de Transações",
        component: AppTransactionsRecord,
      },
      {
        path: "localize-postos",
        name: "Localize postos",
        component: AppGasStationsList,
      },
      {
        path: "extrato-cashback",
        name: "Extrato de Cashback",
        component: AppExtract,
      },
      {
        path: "ranking",
        name: "Ranking de Clientes",
        component: ClientRanking,
      },
      {
        path: "frentistas",
        name: "Frentistas",
        component: AppAttendant,
      },
      //admin pages
      {
        path: "admin/monthly-payment",
        name: "Mensalidade",
        component: AppMonthlyPayment,
      },
      {
        meta: { show: false },
        path: "admin/gas-station-network",
        name: "Rede de Postos",
        component: AppGasStationNetwork,
      },
      {
        meta: { show: false },
        name: "Edição de Rede de Postos",
        path: "admin/gas-station-network/edit/:id",
        component: AppEditNetwork,
      },
      {
        path: "admin/users",
        name: "Usuarios",
        component: Users,
      },
      {
        meta: { show: false },
        path: "admin/users/attendant",
        name: "Frentista",
        component: AppUsersAttendant,
      },
      {
        meta: { show: false },
        path: "admin/users/partner",
        name: "Parceiro",
        component: AppUsersPartner,
      },
      {
        meta: { show: false },
        path: "admin/users/manager",
        name: "Gerente",
        component: AppUsersManager,
      },
      {
        meta: { show: false },
        path: "admin/users/admin",
        name: "Administrador",
        component: UsersAdmin,
      },
      {
        meta: { show: false },
        path: "admin/users/client",
        name: "Motorista",
        component: Users,
      },
      {
        path: "admin/gas-stations",
        name: "Posto",
        component: AppGasStation,
      },
      {
        path: "admin/stores",
        name: "Loja",
        component: CatalogRegister,
      },
      {
        path: "admin/carbon",
        name: "Carbono",
        component: AppCarbon,
      },
      {
        path: "admin/methodology",
        name: "Metodologia",
        component: AppMethodology,
      },
      {
        meta: { show: false },
        path: "admin/users/clients/edit-fuel-transactions/:cpf/:id",
        name: "Editar Abastecimento",
        component: EditFuelTransactions,
      },
      {
        meta: { show: false },
        path: "admin/users/edit-cashback-use",
        name: "Editar Uso de Cashback",
        component: EditCashbackUse,
      },
      {
        meta: { show: false },
        path: "admin/users/edit-register-data/:profile/:id",
        name: "Editar Dados de Usuários",
        component: EditRegisterData,
      },
      {
        meta: { show: false },
        path: "admin/users/edit-manager-data/MANAGER/:id",
        name: "Editar Dados de Gerente",
        component: AppUsersManagerRegister,
      },
      {
        path: "treinamentos",
        name: "Treinamentos",
        component: AppTraining,
      },
    ],
  },
  { path: "/:catchAll(.*)", component: NotFound },
];

export default ClubCarbyRouter;
