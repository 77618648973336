import ManagerExtractModel from "../Model/ManagerExtractModel";

export default class ManagerExtractController {
  managerExtract = new ManagerExtractModel();

  //orderBy => id, fuelType, createdAt

  public getExtract(
    gasStationId: number,
    page = 0,
    linesPerPage = 24,
    direction = "DESC",
    orderBy = "id",
    cpf?
  ) {
    return this.managerExtract
      ._getManagerExtract(
        gasStationId,
        page,
        linesPerPage,
        direction,
        orderBy,
        cpf
      )
      .then((list) => {
        return list.data;
      });
  }

  public getFuelTransactionsByGasStationAndCashbackUse(
    page = 0,
    linesPerPage = 24,
    direction = 'DESC',
    orderBy = 'id',
    gasStationId: number,
    isUsedCashback?: boolean
  ) {
    return this.managerExtract
      .getFuelTransactionsByGasStationAndCashbackUse(
        page,
        linesPerPage,
        direction,
        orderBy,
        gasStationId,
        isUsedCashback
      )
      .then((list) => {
        return list.data;
      });
  }

  public getTotal(gasStationId: number, cpf?) {
    return this.managerExtract
      ._getTotalValues(gasStationId, cpf)
      .then((result) => {
        return result.data;
      });
  }

  public getFuelTransactionHistory(gasStationId, searchTerm = null, startDate = null, endDate = null, page = 0, linesPerPage = 24, direction = 'DESC', orderBy = 'id') {
    return this.managerExtract
      .getFuelTransactionHistory(gasStationId, searchTerm, startDate, endDate, page, linesPerPage, direction, orderBy)
      .then((result) => {
        return result
      })
  }

  public getTotalValuesHistory(gasStationId, searchTerm = null, startDate = null, endDate = null, isUsedCashback = null) {
    return this.managerExtract
      .getTotalValuesHistory(gasStationId, searchTerm, startDate, endDate, isUsedCashback)
      .then((result) => {
        return result
      })
  }

  public downloadExtract(gasStationId, startDate = null, endDate = null) {
    return this.managerExtract
      .downloadExtract(gasStationId, startDate, endDate)
      .then((result) => {
        return result
      })
  }
}
