import { SetAddressModel } from "./AddressInterfaces";

export class SetUserModel {
  user = {
    birthDate: null,
    cpf: null,
    email: null,
    gender: null,
    name: null,
    nationality: null,
    password: null,
    phone: null,
    address: new SetAddressModel(),
  };
}

export class SetAttendantModel {
  user = {
    birthDate: null,
    cpf: null,
    email: null,
    gender: null,
    name: null,
    nationality: null,
    password: null,
    phone: null,
    address: new SetAddressModel(),
  };
  gasStation = {
    id: null,
  };
}

export class SetManagerModel {
  user = {
    birthDate: null,
    cpf: null,
    email: null,
    gender: null,
    name: null,
    nationality: null,
    password: null,
    phone: null,
    address: new SetAddressModel(),
  };
  gasStations = [
    {
      id: null,
    },
  ];
}
export class GetUserModel {
  user = {
    id: null,
    birthDate: null,
    cpf: null,
    email: null,
    gender: null,
    name: null,
    nationality: null,
    password: null,
    phone: null,
    address: new SetAddressModel(),
    isCompleted: false,
  };
}

export interface UserInterface {
  data: [
    {
      cashbackBalance: number;
      user: {
        id?: number;
        birthDate: string;
        cpf: string;
        email: string;
        gender: string;
        name: string;
        nationality: string;
        password: string;
        phone: string;
        isCompleted: boolean;
        address: SetAddressModel;
      };
      gasStation?: {
        id: number;
      };
    }
  ];
}
