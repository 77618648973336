import { SetAddressModel } from "./AddressModel";


// Model User
export class SetUserModel {
  user = {
    birthDate: null,
    cpf: null,
    email: null,
    gender: null,
    name: null,
    nationality: null,
    password: null,
    phone: null,
    address: new SetAddressModel(),
  };
  acceptedTerms = [];
  favoriteGasStations = [];
}

// MOdel Frentista
export class SetAttendantModel {
  user = {
    birthDate: null,
    cpf: null,
    email: null,
    gender: null,
    name: null,
    nationality: null,
    password: null,
    phone: null,
    address: new SetAddressModel(),
    isActive: null
  };
  gasStation = {
    id: null,
  };
}

// Model Gerente
export class SetManagerModel {
  user = {
    birthDate: null,
    cpf: null,
    email: null,
    gender: null,
    name: null,
    nationality: null,
    password: null,
    phone: null,
    address: new SetAddressModel(),
  };
  gasStations = [
    {
      id: null,
    },
  ];
}
export class GetUserModel {
  user = {
    id: null,
    birthDate: null,
    cpf: null,
    email: null,
    gender: null,
    name: null,
    nationality: null,
    password: null,
    phone: null,
    address: new SetAddressModel(),
    isCompleted: false,
  };
}

export interface UserInterface {
  data: {
    loyalUser?: boolean;
    content: [
      {
        user: {
          id?: number;
          birthDate: string;
          cpf: string;
          email: string;
          gender: string;
          name: string;
          nationality: string;
          password: string;
          phone: string;
          isCompleted: boolean;
          address: SetAddressModel;
        };
        // posto de gasolina
        gasStation?: {
          id: number;
        };
      }
    ];
  };
}

export interface GetUserInterface {
  data: {
    id: number;
    user: {
      id?: number;
      birthDate: string;
      cpf: string;
      email: string;
      gender: string;
      name: string;
      nationality: string;
      password: string;
      phone: string;
      isCompleted: boolean;
      address: SetAddressModel;
    };
    // postos de gasolina
    gasStations?: [{ id: number }];
    gasStation?: {
      id: number;
    };
  };
}
