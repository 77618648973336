import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

export default class ContactModel {
  async sendContact(contactInfo: any) {
    return axios.post(`${API_URL}/contact`, contactInfo);
  }

  async sendBudgetInfo(budgetInfo: any) {
    return axios.post(`${API_URL}/budget`, budgetInfo);
  }
}
